export default {
  bookExperiencePanel: '体験予約パネル',
  calendarIcon: 'カレンダーアイコン',
  coverImageForResource: '{{title}}の体験用カバー画像',
  filterBy: '{{filter}}でフィルター',
  filterByDateRange: '日付範囲でフィルター',
  gallery: 'ギャラリー',
  goBack: '前のページに戻る',
  hostUserIcon: 'ホストユーザーのアイコン',
  imageOf: '{{title}}の画像',
  interactiveMapAltText: '選択可能な{{title}}のインタラクティブマップ',
  mediaSlides: 'メディアスライド',
  muteVideo: 'ビデオの音を消す',
  nextPhoto: '次の写真',
  noVideoPlayback: 'ビデオ再生機能なし',
  pauseVideo: 'ビデオを一時停止',
  photoOf: '{{name}}の写真',
  playVideo: 'ビデオを再生',
  previousPhoto: '前の写真',
  reserveSelectedSlot: '選択したスロットを予約',
  selectDate: '日付を選択',
  selectParticipants: '参加者を選択',
  selectSessionTime: 'セッション時間を選択',
  unmuteVideo: 'ビデオの音を戻す',
  viewShoppingCart: 'カートを見る',
  zoomIn: '拡大',
  zoomOut: '縮小',
  selectData: '{{data}}を選択',
  reserveName: '{{name}}を予約',
  deleteReservation: '{{esperienceTitle}}の予約を削除',
  editParticipantsFor: '{{esperienceTitle}}の参加者を編集',
  enterPromoCode: 'プロモーションコードを入力',
  applyPromoCode: 'プロモーションコードを適用',
  increaseAddOnAmount: '選択した{{addOn}}を増やす',
  decreaseAddOnAmount: '選択した{{addOn}}を減らす',
  addOnModal: '{{addOn}}モーダル',
  moreInformationAboutAddOn: '{{addOn}}についての詳細情報',
  decreasePriceTierTickets: '{{priceTier}}チケットを減らす',
  increasePriceTierTickets: '{{priceTier}}チケットを増やす',
  downloadFilename: '{{filename}}をダウンロード',
};
