import React, {
  useMemo,
  useContext,
  useState,
  useEffect,
  FormEvent,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  DiscardCartEndpoint,
  ANALYTICS_EVENT,
  StripePaymentMethods,
} from '@kouto/types';
import { useIsMobile } from 'WindowDimensionProvider';
import { PaymentIntentResult } from '@stripe/stripe-js';
import moment from 'moment';
import styled from 'styled-components';
import { FormProvider, useForm } from 'hook-form';
import { v4 as uuidv4 } from 'uuid';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { PaymentSection } from 'features/ShoppingCart/components/PaymentSection';
import { ExternalLink } from 'components/ExperienceView/styles';
import { Sep } from 'components/common/styled/common-styled';
import { PaymentContext } from 'features/ShoppingCart/contexts/PaymentContext';
import { createBulkBooking, validateRoomChargeAction } from 'actions/booking';
import { PaymentMethod } from 'types/payment';
import CheckboxField from 'components/common/CheckboxField/CheckboxField';
import { validateEmpty } from 'utils/validators';
import fetchApi from 'utils/fetch';
import { PrimaryButton } from 'components/theme/Button/Button';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { PurchaserSection } from 'features/ShoppingCart/components/PurchaserSection';
import * as Styled from 'features/ShoppingCart/utils/styles';
import useQueryParams from 'hooks/useQueryParams';
import CardIconSvg from 'assets/icon-card';
import ApplePayIconSvg from 'assets/icon-apple-pay';
import GooglePayIconSvg from 'assets/icon-google-pay';
import {
  IBookingError,
  ICheckoutConfig,
  IExperiencePayload,
} from 'features/ShoppingCart/types';
import {
  formatDateForDisplay,
  isCreditCharge,
  isMemberCharge,
  isRoomCharge,
  isKICCCharge,
  isIntegrationChargeOption,
  serializeParams,
} from 'utils';
import {
  CREATE_BULK_BOOKING_FAILURE,
  CREATE_PMS_VALIDATION_FAILURE,
} from 'types/booking';
import { STATUS_PENDING, STATUS_SUCCESS } from 'types/app';
import useSearchQueryParams from 'hooks/use-search-params';
import useCustomHistory from 'hooks/use-custom-history';
import { filterEmpty } from 'actions/helpers';
import { Error as FormError } from 'components/theme/Form/Form';
import { isPMSEnabledForCartExperience } from 'features/ShoppingCart/utils';
import {
  creditCardValidation,
  pmsAuthValidation,
  pmsDateValidation,
} from 'features/ShoppingCart/utils/validate';
import { isIntegratedPaymentMethodEnabledForBrand } from 'components/helpers';
import { resetCart, setCartId } from 'actions/cart';
import { ValidationError } from 'types/api.types';
import { useDebugLog } from 'utils/debug';
import { GuestSurveyModal, useGuestSurveyModal } from 'features/GuestSurvey';
import { usePurchaserFromEmbedConfig } from 'features/EmbedConfig';
import useCartItemsListings from 'hooks/useCartItemsListings';
import { usePaymentOptions } from 'features/ShoppingCart/hooks/usePaymentOptions';

import CashAppIcon from 'assets/payment/cash-app';
import AmazonPayIcon from 'assets/payment/amazon-pay';
import AliPayIcon from 'assets/payment/ali-pay';
import LinkPayIcon from 'assets/payment/link-pay';
import WeChatPayIcon from 'assets/payment/we-chat-pay';
import useCartPaymentIntent from './hooks/useCartPaymentIntent';
import { getCartItemsData } from './checkoutUtils';

const paymentMethodMap: Record<string, string> = {
  [StripePaymentMethods.CASH_APP_PAY]: 'Cash App Pay',
  [StripePaymentMethods.LINK]: 'Link',
  [StripePaymentMethods.ALI_PAY]: 'Alipay',
  [StripePaymentMethods.AMAZON_PAY]: 'Amazon Pay',
  [StripePaymentMethods.WECHAT_PAY]: 'WeChat Pay',
};

interface ICheckoutProps {
  experienceList: IExperiencePayload;
  bookingApprovalError?: ValidationError[];
}

const CheckoutPage: React.FC<ICheckoutProps> = ({
  experienceList,
  bookingApprovalError,
}) => {
  const { t } = useTranslation();
  const debug = useDebugLog();
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const { theme } = useAppState();
  const isMobile = useIsMobile();
  const paymentOptions = usePaymentOptions();
  const { searchParams } = useSearchQueryParams();
  const { push: customPush, removeSearchQuery } = useCustomHistory();
  const {
    cartTotal,
    confirmCardPaymentCallback,
    submitCardPaymentCallback,
    getPaymentIntentInfoCallback,
  } = useContext(PaymentContext);
  const [bookingIsSuccessful, setBookingIsSuccessful] = useState(false);
  const [bookingError, setBookingError] = useState<IBookingError>(null);
  const [isStripeProcessing, setStripeProcessing] = useState(false);
  const { discountCode } = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );
  const {
    bookingContext,
    createBulkBookingStatus,
    validatingStatus,
    validationData,
  } = useAppState((state: Record<string, unknown>) => state.booking);
  const { settings, integrations } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const { cartId, cartItems, listings, isLoading } = useCartItemsListings();
  const totalCartAmoutIsFree = cartTotal === 0;

  const { paymentIntent, paymentIntentSecret } =
    useCartPaymentIntent(cartTotal);

  const integrationType = useMemo(() => {
    const firstListing = Object.values(listings)?.[0];
    const integrationMeta =
      firstListing?.integrationMeta?.[0] ??
      firstListing?.resourceGroups?.[0]?.integrationMeta?.[0];

    return integrationMeta?.type;
  }, [listings]);

  const waitlistData = useQueryParams<{
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    phoneNumber?: string;
  }>();
  const embedUserData = usePurchaserFromEmbedConfig();

  const methods = useForm<ICheckoutConfig>({
    mode: 'onChange',
    defaultValues: {
      purchaser: bookingContext?.purchaser ?? embedUserData ?? waitlistData,
      paymentName:
        bookingContext?.purchaser?.lastName ??
        embedUserData?.lastName ??
        waitlistData?.lastName,
      reservationNumber: embedUserData?.reservationNumber,
      activeOption: paymentOptions?.[0]?.name ?? undefined,
      stripeSelectedMethod: StripePaymentMethods.CARD,
    },
  });

  useEffect(() => {
    if (paymentOptions.length === 1) {
      methods.setValue('activeOption', paymentOptions[0].name);
    }
  }, [paymentOptions.length]);

  const setCreditCardError = () => {
    methods.setError('card', {
      type: 'custom',
      message: creditCardValidation.message,
    });
  };

  const {
    surveyModalOpen,
    presentSurvey,
    onCloseSurveyModal,
    cartId: cartIdForSurvey,
  } = useGuestSurveyModal();

  const redirectToConfirmation = (emailAddress: string) => {
    dispatch(resetCart(brandId));
    localStorage.removeItem('cart');
    sessionStorage.removeItem('price-tier');

    const bookingSuccessConfirmationParams = {
      status: STATUS_SUCCESS,
      emailAddress,
      lang: searchParams?.lang,
      debug: searchParams?.debug,
    };

    window.sessionStorage.setItem('confirmation_email', emailAddress);

    customPush({
      pathname: `/e/booking/confirmation`,
      search: serializeParams(filterEmpty(bookingSuccessConfirmationParams)),
      unset: [
        'mode',
        'noOfGuests',
        'sessionTime',
        'duration',
        'sessionDate',
        'supportedParticipantsCount',
        'email',
        'emailAddress',
        'payment_intent',
        'redirect_status',
        'payment_intent_client_secret',
      ],
    });
  };

  const pickPayloadData = (data: ICheckoutConfig) => {
    const { activeOption } = data;
    if (
      activeOption === PaymentMethod.MEMBER_NUMBER ||
      (activeOption === PaymentMethod.ROOM_CHARGE &&
        !isPMSEnabledForCartExperience({
          activeOption,
          cartItems,
          listings,
        }))
    ) {
      return {
        lastName: data?.paymentName,
        methodNumber: data?.reservationNumber,
      };
    }

    return {
      lastName: data.paymentName || data?.purchaser?.lastName,
      reservationNumber: data.reservationNumber,
    };
  };

  const getValidationData = async (data: ICheckoutConfig) => {
    const response = await dispatch(
      validateRoomChargeAction({
        payload: pickPayloadData(data),
        brandId,
        integrationType,
      }),
    );

    const validationDataResponse =
      response?.payload?.response ?? validationData;

    if (response.type === CREATE_PMS_VALIDATION_FAILURE) {
      return { error: pmsAuthValidation };
    }

    const isDateValid = Object.values(cartItems).every((item) => {
      const experienceDate = moment
        .parseZone(item.sessionDateTime)
        .format('YYYY-MM-DD');

      return moment(experienceDate).isBetween(
        formatDateForDisplay(validationDataResponse?.arrivalDate),
        formatDateForDisplay(validationDataResponse?.departureDate),
        'days',
        '[]',
      );
    });
    if (!isDateValid) {
      return { error: pmsDateValidation };
    }
    return { error: false, validationDataResponse };
  };

  const handlePaymentError = async (
    paymentResult: PaymentIntentResult | null,
    data?: ICheckoutConfig,
    errorMessage = '',
  ) => {
    window.focus();
    if (paymentIntent) {
      const discardCartUrl = DiscardCartEndpoint.url({
        brandId,
        cartId,
      }).substring(1);
      await fetchApi.post(discardCartUrl);
    }
    dispatch(
      setCartId({
        brandId,
        cartId: uuidv4(),
      }),
    );
    debug('error', {
      paymentResult,
      formData: data,
    });
    if (
      stripeSelectedMethod !== StripePaymentMethods.APPLE_PAY &&
      stripeSelectedMethod !== StripePaymentMethods.GOOGLE_PAY
    ) {
      setBookingError([
        {
          message: errorMessage.includes('payment_method_data')
            ? 'The provided payment method has failed authentication. Please try again.'
            : errorMessage,
          code: 'booking-stripe-error-stripe-error',
        },
      ]);
    }
  };

  const handlePaymentSuccess = (cartId: string, email: string) => {
    window.focus();
    setBookingIsSuccessful(true);
    presentSurvey(cartId, () => {
      redirectToConfirmation(email);
    });
  };

  const handleStripeRedirection = async () => {
    if (
      searchParams?.payment_intent &&
      searchParams?.redirect_status &&
      searchParams?.payment_intent_client_secret &&
      searchParams?.email
    ) {
      const parent = window.opener || window.parent;

      if (parent.location.origin === window.origin) {
        parent.focus();
        window.close();
      }

      const email = searchParams?.email;

      await confirmPaymentIntentStatus({
        paymentIntentGetter: getPaymentIntentInfoCallback,
        email: email ?? '',
        cartId: searchParams?.cartId ?? cartId,
        clientSecret: searchParams?.payment_intent_client_secret,
      });
    }
  };

  const confirmPaymentIntentStatus = async ({
    paymentIntentGetter,
    clientSecret,
    email,
    cartId,
    data,
  }: {
    paymentIntentGetter: typeof confirmCardPaymentCallback;
    clientSecret: string;
    cartId: string;
    email: string;
    data?: ICheckoutConfig;
  }) => {
    setStripeProcessing(true);
    let paymentResult: PaymentIntentResult | null = null;
    if (!paymentIntentGetter) {
      return;
    }
    try {
      paymentResult = await paymentIntentGetter(clientSecret);

      const failed =
        !paymentResult ||
        paymentResult.error ||
        (paymentResult.paymentIntent &&
          paymentResult.paymentIntent?.status !== 'succeeded');

      if (failed) {
        const message =
          paymentResult?.error?.message ||
          paymentResult?.paymentIntent?.last_payment_error?.message ||
          'Something went wrong. Please try again.';
        await handlePaymentError(paymentResult, data, message);

        return;
      }

      handlePaymentSuccess(cartId, email);
    } catch (err) {
      const error = err as Error;
      const message =
        error?.message || 'Something went wrong with Stripe Payment';
      handlePaymentError(paymentResult, data, message);
    } finally {
      removeSearchQuery(
        'email',
        'payment_intent',
        'redirect_status',
        'payment_intent_client_secret',
      );
      setStripeProcessing(false);
    }
  };

  useEffect(() => {
    if (!getPaymentIntentInfoCallback) {
      return;
    }
    handleStripeRedirection();
  }, [getPaymentIntentInfoCallback]);

  const handlePaymentSubmit = async (
    response: Record<string, string>,
    payload: ICheckoutConfig['purchaser'],
    data: ICheckoutConfig,
  ) => {
    const { activeOption } = data;

    if (isKICCCharge(activeOption)) {
      if (response.clientSecret) {
        const { clientSecret } = response;
        window.location.href = clientSecret;
      }
      // TODO: handle failure
      return;
    }

    if (!confirmCardPaymentCallback) {
      console.error('confirmCardPaymentCallback is not defined');
      return;
    }

    await confirmPaymentIntentStatus({
      paymentIntentGetter: confirmCardPaymentCallback,
      clientSecret: response.clientSecret,
      cartId: response.subjectId,
      email: payload.primaryContactEmailAddress || payload.emailAddress,
      data,
    });
  };

  const shouldValidate = (formData: ICheckoutConfig) => {
    const { activeOption, paymentName, reservationNumber } = formData;
    if (activeOption === PaymentMethod.CREDIT_CARD) {
      return (
        !totalCartAmoutIsFree &&
        paymentName &&
        reservationNumber &&
        isIntegratedPaymentMethodEnabledForBrand(integrations, settings)
      );
    }

    if (activeOption === PaymentMethod.ROOM_CHARGE) {
      const roomChargeConfig = paymentOptions.find((p) => {
        return p.name === PaymentMethod.ROOM_CHARGE;
      });
      if (
        totalCartAmoutIsFree &&
        !roomChargeConfig?.shouldValidateComplimentaryBooking
      ) {
        return false;
      }
      return isIntegratedPaymentMethodEnabledForBrand(integrations, settings);
    }

    return false;
  };

  const handleBookingError = (
    formData: ICheckoutConfig,
    bookingResponse: any,
  ) => {
    const { activeOption } = formData;
    const {
      payload: { validationMessages },
      cartItemsError,
    } = bookingResponse;

    let errorMessage = t('bookBulkErrorByCard');
    if (activeOption === PaymentMethod.ROOM_CHARGE) {
      errorMessage = t('bookBulkErrorByRoomCharge');
    } else if (activeOption === PaymentMethod.MEMBER_NUMBER) {
      errorMessage = t('bookBulkErrorByMemberCharge');
    }

    dispatch(
      setCartId({
        brandId,
        cartId: uuidv4(),
      }),
    );
    debug('error', { createBookingError: errorMessage, formData });

    if (cartItemsError?.length) {
      setBookingError(cartItemsError);
      return;
    }

    setBookingError(
      Array.isArray(validationMessages as IBookingError) &&
        validationMessages.length
        ? validationMessages
        : [
            {
              message: errorMessage,
              code: 'booking-server-error',
            },
          ],
    );
  };

  const doBooking = async (
    formData: ICheckoutConfig,
    integrationValidationResponse: unknown,
  ) => {
    const { activeOption } = formData;

    const paymentMethodConfig = paymentOptions.find((p) => {
      return p.name === activeOption;
    });
    const shouldValidateOnFullDiscount =
      !paymentMethodConfig?.shouldValidateComplimentaryBooking
        ? totalCartAmoutIsFree
        : false;

    const isDetailOmit =
      shouldValidateOnFullDiscount ||
      (isIntegrationChargeOption(activeOption) &&
        (!formData.paymentName || !formData.reservationNumber));

    const bulkBookingData = {
      ...formData,
      purchaser: {
        ...formData.purchaser,
        emailAddress: formData.purchaser.emailAddress,
      },
      couponCode: discountCode,
      data: getCartItemsData({
        cartItems,
        purchaserFirstName: formData.purchaser.firstName,
        purchaserLastName: formData.purchaser.lastName,
        discountCode,
      }),
      paymentMethod: activeOption ?? PaymentMethod.CREDIT_CARD,
      validationData: integrationValidationResponse,
      integrationType,
      paymentDetail: isDetailOmit ? null : pickPayloadData(formData),
    };

    return dispatch(createBulkBooking(brandId, cartId, bulkBookingData));
  };

  const doCheckout = async (formData: ICheckoutConfig) => {
    const { activeOption, card } = formData;
    if (isCreditCharge(activeOption) && !card && !totalCartAmoutIsFree) {
      setCreditCardError();
      return;
    }

    let integrationValidationResponse = {};
    if (shouldValidate(formData)) {
      const { error, validationDataResponse } = await getValidationData(
        formData,
      );

      if (error) {
        debug('error', { bookingValidationError: error, formData });
        setBookingError([error] as IBookingError);
        return;
      }

      if (validationDataResponse) {
        integrationValidationResponse = validationDataResponse;
      }
    }

    if (isCreditCharge(activeOption)) {
      if (submitCardPaymentCallback) {
        const submitResult = await submitCardPaymentCallback();
        if (!submitResult || submitResult?.error) {
          console.error(
            `submitCardPaymentCallback error: ${submitResult?.error}`,
          );
          return;
        }
      } else {
        console.error('submitCardPaymentCallback is not defined');
      }
    }

    const bookingResponse = await doBooking(
      formData,
      integrationValidationResponse,
    );

    if (bookingResponse.type === CREATE_BULK_BOOKING_FAILURE) {
      handleBookingError(formData, bookingResponse);
      return;
    }

    if (
      isRoomCharge(activeOption) ||
      isMemberCharge(activeOption) ||
      totalCartAmoutIsFree
    ) {
      handlePaymentSuccess(
        bookingResponse.payload.response.subjectId,
        formData.purchaser.emailAddress,
      );
      return;
    }

    handlePaymentSubmit(
      bookingResponse.payload.response,
      formData.purchaser,
      formData,
    );
  };

  const onSubmit = (props: FormEvent<HTMLFormElement>) => {
    methods.clearErrors();
    return methods.handleSubmit(doCheckout)(props);
  };

  const isWaitingPayment = () => {
    return (
      validatingStatus === STATUS_PENDING ||
      createBulkBookingStatus === STATUS_PENDING ||
      isStripeProcessing
    );
  };

  const selectedPaymentMethod = methods.watch('activeOption');
  const stripeSelectedMethod = methods.watch('stripeSelectedMethod');

  const completeButtonLabel = useMemo(() => {
    if (isWaitingPayment()) {
      return t('waiting');
    }

    if (totalCartAmoutIsFree) {
      return t('complete');
    }

    if (isCreditCharge(selectedPaymentMethod)) {
      if (
        stripeSelectedMethod === StripePaymentMethods.APPLE_PAY ||
        stripeSelectedMethod === StripePaymentMethods.GOOGLE_PAY
      ) {
        return t('payWith');
      }
      if (paymentMethodMap?.[stripeSelectedMethod]) {
        return `${t('payWith')} ${paymentMethodMap[stripeSelectedMethod]}`;
      }
      return t('payWithCreditCard');
    }
    if (isRoomCharge(selectedPaymentMethod)) {
      return t('payWithRoomCharge');
    }
    if (isMemberCharge(selectedPaymentMethod)) {
      return t('payWithMemberCharge');
    }

    return t('complete');
  }, [
    selectedPaymentMethod,
    totalCartAmoutIsFree,
    stripeSelectedMethod,
    isWaitingPayment,
    t,
  ]);

  const ContinueButtonIcon = useMemo(() => {
    if (isWaitingPayment() || cartTotal === 0) {
      return null;
    }

    if (isCreditCharge(selectedPaymentMethod)) {
      if (stripeSelectedMethod === StripePaymentMethods.APPLE_PAY) {
        return ApplePayIconSvg;
      }
      if (stripeSelectedMethod === StripePaymentMethods.GOOGLE_PAY) {
        return GooglePayIconSvg;
      }
      if (stripeSelectedMethod === StripePaymentMethods.AMAZON_PAY) {
        return AmazonPayIcon;
      }
      if (stripeSelectedMethod === StripePaymentMethods.ALI_PAY) {
        return AliPayIcon;
      }
      if (stripeSelectedMethod === StripePaymentMethods.CASH_APP_PAY) {
        return CashAppIcon;
      }
      if (stripeSelectedMethod === StripePaymentMethods.LINK) {
        return LinkPayIcon;
      }
      if (stripeSelectedMethod === StripePaymentMethods.WECHAT_PAY) {
        return WeChatPayIcon;
      }
      return CardIconSvg;
    }

    return null;
  }, [
    selectedPaymentMethod,
    stripeSelectedMethod,
    cartTotal,
    isWaitingPayment,
  ]);

  return (
    <>
      <FormProvider {...methods}>
        <Styled.FormSection onSubmit={onSubmit}>
          <LoadingContainer
            className={
              searchParams?.payment_intent_client_secret ? 'loading' : ''
            }
          >
            {isLoading || !Object.values(cartItems).length ? (
              <>
                <SkeletonLine style={{ height: 100 }} translucent />
                <SkeletonLine style={{ height: 300 }} translucent />
              </>
            ) : (
              <>
                <Styled.HeadTitle>{t('checkout')}</Styled.HeadTitle>
                <PurchaserSection experiences={experienceList} />
                <PaymentContainer>
                  <PaymentSection
                    paymentOptions={paymentOptions}
                    paymentIntentSecret={paymentIntentSecret}
                    totalCartAmoutIsFree={totalCartAmoutIsFree}
                  />
                  {(bookingError || bookingApprovalError) && (
                    <PaymentValidation>
                      {(bookingError || bookingApprovalError)?.map(
                        (validationMessageObject) => {
                          return (
                            <FormError
                              key={`booking-validation-message-${validationMessageObject.code}`}
                              style={{ marginBottom: 5 }}
                            >
                              {validationMessageObject.message}
                            </FormError>
                          );
                        },
                      )}
                    </PaymentValidation>
                  )}
                  <KoutoTermsContainer>
                    <CheckboxField
                      id="termsCheckBox"
                      name="termsCheckBox"
                      label={
                        <KoutoTermsText
                          aria-label={t('policyAggreementText').replace(
                            /<[^>]*>/g,
                            '',
                          )}
                        >
                          <Trans
                            i18nKey="policyAggreementText"
                            components={[
                              <ExternalLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.way.co/legal/legal-agreements"
                              />,
                              <ExternalLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.way.co/legal/privacy-notice"
                              />,
                            ]}
                          />
                        </KoutoTermsText>
                      }
                      rules={{
                        ...validateEmpty('termsAggreementValidation'),
                      }}
                    />
                  </KoutoTermsContainer>
                </PaymentContainer>
                <PrimaryButton
                  big
                  className="cart__form-button-full cart__complete-checkout-button"
                  type="submit"
                  disabled={
                    isWaitingPayment() ||
                    bookingIsSuccessful ||
                    String(searchParams.latest).toLowerCase() === 'true'
                  }
                  analyticEvent={ANALYTICS_EVENT.CLICK_COMPLETE_PAYMENT_BUTTON}
                  analyticData={{
                    ...(discountCode?.code && {
                      discountCode: discountCode?.code,
                    }),
                    paymentMethod:
                      methods.getValues('activeOption') ??
                      PaymentMethod.CREDIT_CARD,
                  }}
                >
                  {completeButtonLabel}{' '}
                  {ContinueButtonIcon ? (
                    <ContinueButtonIcon
                      color={theme?.colors?.buttonColorContrastShades?.[100]}
                    />
                  ) : null}
                </PrimaryButton>

                {isMobile && <Sep style={{ margin: '48px 0px' }} />}
              </>
            )}
          </LoadingContainer>
        </Styled.FormSection>
      </FormProvider>
      <GuestSurveyModal
        open={surveyModalOpen}
        onClose={onCloseSurveyModal}
        cartId={cartIdForSurvey}
      />
    </>
  );
};

const KoutoTermsContainer = styled.div`
  margin-bottom: 24px;
`;

const KoutoTermsText = styled.span`
  font-size: 14px;
`;

export const PaymentContainer = styled.div``;

export const PaymentValidation = styled.div`
  margin: 15px 0;
`;

export const LoadingContainer = styled.div`
  &.loading {
    position: relative;
    min-height: 80vh;
    display: grid;
    place-items: center center;
    > * {
      opacity: 0;
      pointer-events: none;
    }
    &:before {
      margin-top: 20vh;
      content: '';
      width: 40px;
      height: 40px;
      border: 4px solid rgba(0, 0, 0, 0.2);
      border-top-color: #000;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default CheckoutPage;
