export default {
  bookExperiencePanel: "Panneau de réservation d'expérience",
  calendarIcon: 'Icône de calendrier',
  coverImageForResource: 'Image de couverture pour les expériences {{title}}',
  filterBy: 'Filtrer par {{filter}}',
  filterByDateRange: 'Filtrer par plage de dates',
  gallery: 'Galerie',
  goBack: 'Retour à la page précédente',
  hostUserIcon: "Icône d'utilisateur hôte",
  imageOf: 'Image de {{title}}',
  interactiveMapAltText: 'Carte interactive avec sélection de {{title}}',
  mediaSlides: 'Diapositives multimédia',
  muteVideo: 'Couper le son de la vidéo',
  nextPhoto: 'Photo suivante',
  noVideoPlayback: 'Aucune capacité de lecture vidéo',
  pauseVideo: 'Mettre la vidéo en pause',
  photoOf: 'Photo de {{name}}',
  playVideo: 'Lire la vidéo',
  previousPhoto: 'Photo précédente',
  reserveSelectedSlot: 'Réserver le créneau sélectionné',
  selectDate: 'Sélectionner la date',
  selectParticipants: 'Sélectionner les participants',
  selectSessionTime: "Sélectionner l'heure de la session",
  unmuteVideo: 'Rétablir le son de la vidéo',
  viewShoppingCart: 'Voir le panier',
  zoomIn: 'Zoomer',
  zoomOut: 'Dézoomer',
  selectData: 'Sélectionner {{data}}',
  reserveName: 'Réserver {{name}}',
  increaseAddOnAmount: 'Augmenter {{addOn}} sélectionné',
  decreaseAddOnAmount: 'Diminuer {{addOn}} sélectionné',
  addOnModal: '{{addOn}} modal',
  moreInformationAboutAddOn: 'Plus d’informations sur {{addOn}}',
  decreasePriceTierTickets: 'Diminuer les billets {{priceTier}}',
  increasePriceTierTickets: 'Augmenter les billets {{priceTier}}',
  downloadFilename: 'Télécharger {{filename}}',
};
