export default {
  bookExperiencePanel: 'Панель бронирования опыта',
  calendarIcon: 'Иконка календаря',
  coverImageForResource: 'Обложка для опыта {{title}}',
  filterBy: 'Фильтр по {{filter}}',
  filterByDateRange: 'Фильтр по диапазону дат',
  gallery: 'Галерея',
  goBack: 'Назад к предыдущей странице',
  hostUserIcon: 'Иконка хоста',
  imageOf: 'Изображение {{title}}',
  interactiveMapAltText: 'Интерактивная карта с выбором {{title}}',
  mediaSlides: 'Медиа слайды',
  muteVideo: 'Отключить звук видео',
  nextPhoto: 'Следующее фото',
  noVideoPlayback: 'Нет возможности воспроизведения видео',
  pauseVideo: 'Пауза видео',
  photoOf: 'Фото {{name}}',
  playVideo: 'Воспроизвести видео',
  previousPhoto: 'Предыдущее фото',
  reserveSelectedSlot: 'Забронировать выбранное время',
  selectDate: 'Выберите дату',
  selectParticipants: 'Выберите участников',
  selectSessionTime: 'Выберите время сеанса',
  unmuteVideo: 'Включить звук видео',
  viewShoppingCart: 'Посмотреть корзину',
  zoomIn: 'Увеличить',
  zoomOut: 'Уменьшить',
  selectData: 'Выберите {{data}}',
  reserveName: 'Забронировать {{name}}',
  deleteReservation: 'Удалить бронирование {{esperienceTitle}}',
  editParticipantsFor: 'Редактировать участников для {{esperienceTitle}}',
  increaseAddOnAmount: 'Увеличить выбранное {{addOn}}',
  decreaseAddOnAmount: 'Уменьшить выбранное {{addOn}}',
  addOnModal: '{{addOn}} модальное окно',
  moreInformationAboutAddOn: 'Больше информации о {{addOn}}',
  decreasePriceTierTickets: 'Уменьшить {{priceTier}} билеты',
  increasePriceTierTickets: 'Увеличить {{priceTier}} билеты',
  downloadFilename: 'Скачать {{filename}}',
};
