export default {
  bookExperiencePanel: "Pannello di prenotazione dell'esperienza",
  calendarIcon: 'Icona del calendario',
  coverImageForResource: 'Immagine di copertina per le esperienze {{title}}',
  filterBy: 'Filtra per {{filter}}',
  filterByDateRange: 'Filtra per intervallo di date',
  gallery: 'Galleria',
  goBack: 'Torna alla pagina precedente',
  hostUserIcon: 'Icona utente host',
  imageOf: 'Immagine di {{title}}',
  interactiveMapAltText: 'Mappa interattiva con selezione di {{title}}',
  mediaSlides: 'Diapositive multimediali',
  muteVideo: 'Disattiva audio video',
  nextPhoto: 'Foto successiva',
  noVideoPlayback: 'Nessuna capacità di riproduzione video',
  pauseVideo: 'Metti in pausa il video',
  photoOf: 'Foto di {{name}}',
  playVideo: 'Riproduci video',
  previousPhoto: 'Foto precedente',
  reserveSelectedSlot: 'Prenota la fascia oraria selezionata',
  selectDate: 'Seleziona la data',
  selectParticipants: 'Seleziona i partecipanti',
  selectSessionTime: "Seleziona l'orario della sessione",
  unmuteVideo: 'Riattiva audio video',
  viewShoppingCart: 'Visualizza il carrello',
  zoomIn: 'Ingrandisci',
  zoomOut: 'Riduci',
  selectData: 'Seleziona {{data}}',
  reserveName: 'Prenota {{name}}',
  deleteReservation: 'Elimina la prenotazione di {{esperienceTitle}}',
  editParticipantsFor: 'Modifica partecipanti per {{esperienceTitle}}',
  enterPromoCode: 'Inserisci il codice promozionale',
  applyPromoCode: 'Applica codice promozionale',
  increaseAddOnAmount: 'Aumenta {{addOn}} selezionato',
  decreaseAddOnAmount: 'Diminuisci {{addOn}} selezionato',
  addOnModal: '{{addOn}} popup',
  moreInformationAboutAddOn: 'Ulteriori informazioni su {{addOn}}',
  decreasePriceTierTickets: 'Diminuisci i biglietti di {{priceTier}}',
  increasePriceTierTickets: 'Aumenta i biglietti di {{priceTier}}',
  downloadFilename: 'Scarica {{filename}}',
};
